@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Goldman&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Entire website background */
  /* background-color: grey; */
  background-image: url(./components/images/background3.jpg);

  /* Create a parallax effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /*Remove scrollbar */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
  /* Hide scrollbar for Firefox and IE */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

/* Content for each page */

.content {
  flex-direction: row;
}

p {
  font-family: "Lora", serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Goldman", cursive;
}

.bg-blk-o {
  background: rgba(0, 0, 0, 0.4);
}

.bd-white {
  border: 2px solid white;
  border-radius: 20px;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}
