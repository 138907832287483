.homepage {
  background: rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;
  min-height: 100vh;
  position: relative;
}

.home-content-container {
  min-height: 100vh;
  align-items: center;
}

.photo {
  width: 100%;
  max-width: 500px;
  margin: auto;
  border-radius: 100px;
}

.photo-container {
  display: flex;
  justify-content: space-between;
}

.statement-container {
  color: rgb(255, 255, 255);
  align-items: center;
}

.text-name {
  font-family: "Hurricane", cursive;
  font-size: 4rem;
  color: silver;
}

.text-job-title {
  font-family: "Orbitron", sans-serif;
  font-size: 3.5rem;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: bold;
  background-image: url(../images/giphy.gif);
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

.home-paragraph {
  font-size: 1.5rem;
  text-indent: 2rem;
}

.resume-link a:hover {
  text-decoration: none;
}

@media (min-width: 1000px) {
  .text-job-title {
    font-size: 5rem;
  }

  .home-paragraph {
    font-size: 2rem;
  }
}
