.card {
  background-color: rgba(0, 0, 0, 0.2);

  color: white;
  display: inline-flex;
  /* max-width: 80%;
  max-height: 80%; */
  margin: 2rem;
  /* border-radius: 20px;
  border: 2px solid white; */
  overflow: hidden;
}

.card-img-top {
  object-fit: cover;
  aspect-ratio: 1.5;
}

.card-title {
  font-size: 24px;
  text-align: center;
  margin: 2rem;
}

.card-text {
  text-align: center;
  font-size: 1.3rem;
  align-items: flex-end;
  height: 250px;
}

.link-button {
  /* position: absolute;
  bottom: 0; */
  inline-size: 50%;
  margin: 0 auto;
}
