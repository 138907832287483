.portfoliopage {
  margin: auto;
  background: rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  max-width: 1200px;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  display: flex;
  align-items: center;
  position: relative;

  border-top: 2px solid;
}

.project-statement {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin: 4rem;
}

.portfolio-content {
  display: flex;
  flex-direction: row;
  inline-size: 100%;
  border-radius: 50px;
}

.carousel-container {
  width: 100%;
  max-width: 1000px;
  margin: 4rem 0;
}
