.navbar {
  display: flex;
  z-index: 10;
  height: 3rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  position: fixed;
  justify-content: center;
}

.navbar a {
  all: unset;
}

.nav-items {
  flex: 1;
  max-width: 1200px;
  display: inline-flex;
  align-items: center;
  padding: 0px 50px;
  flex-direction: row;
  color: white;
}

.links {
  flex: 1;
  display: inline-flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.email-text {
  margin-bottom: unset;
}
/* 
.email-text-firstname {
  -webkit-text-stroke: 1px rgb(66 133 244);
}


.email-text-lastname {
  -webkit-text-stroke: 1px rgb(244 180 0);
}

.email-text-link {
  -webkit-text-stroke: 1px rgb(15 157 88);
} */

.pages {
  flex: 2;
  display: inline-flex;
  justify-content: flex-end;
  gap: 20px;
}

.pages:hover {
  cursor: pointer;
}

.github:hover {
  cursor: pointer;
}

.linkedin:hover {
  cursor: pointer;
}

@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

/* Nav button animation */
.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pop:hover,
.hvr-pop:focus,
.hvr-pop:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  color: rgba(85, 163, 252, 0.9);
}

@media only screen and (max-width: 760px) {
  .navbar {
    max-width: 2rem;
    height: 100%;
    align-self: flex-end;
    top: 0;
    right: 0;
    align-items: stretch;
  }

  .links {
    writing-mode: vertical-rl;
  }

  .pages {
    writing-mode: vertical-rl;
    text-orientation: upright;
    align-items: center;
  }

  .mobile-border {
    border: 1px solid white;
    border-radius: 25px;
    background-color: red;
  }
}
