.contactpage {
  background: rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  border-top: 2px solid;
}

.formbox {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.formbox input,
textarea {
  width: 75vw;
  max-width: 700px;
}

.formbox textarea {
  height: 20vh;
}
.portfolio-copyright {
  text-align: center;
}

.submit-button {
  border-radius: 50px;
}

.sentForm {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-size: 3rem;
  transition-delay: 300ms;
}
