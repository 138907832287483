.aboutpage {
  background: rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  max-width: 1200px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  border-top: 2px solid;
}

.aboutpage p {
  white-space: normal;
}
.about-content {
  display: flex;
  justify-content: space-between;
}

.self-description {
  /* background: rgba(0, 0, 0, 0.3); */
  font-size: 24px;
  /* text-shadow: 2px 2px darkgrey; */
  color: white;
  line-height: 2em;
  max-width: 600px;
  padding: 3rem;
  margin: 1rem;
}

.self-description p {
  display: list-item;
}

.skills-set {
  color: white;
  max-width: 600px;
  margin: 1rem;
}

.main-skills {
  font-size: 1rem;
}

.familiar-skills {
  font-size: 1rem;
}
